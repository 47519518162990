import React, { PropsWithChildren, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ModalProps } from 'react-bootstrap/Modal'
import isFunction from 'lodash/isFunction'
import { useDragAndDrop } from 'src/context/DragAndDrop/DragAndDrop.context'
import {
  CloseButton,
  MediumCancelButton,
  StandardButton,
  StandardButtonSize,
  StandardButtonVariant,
} from '../Reusable/Buttons'
import { ActivateSVG } from '../../components/Icons/Basic'
import { ModalFormToggle } from './ModalFormToggle'
import {
  BackIcon,
  ModalBody,
  ModalButtonGroup,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from './ModalForm.styles'
import { ModalContainer } from './ModalContainer'
import { AIPoweredModalHeader } from './ModalForm.components'

export const ModalFormIdQueryParam = 'modalFormId'

export enum ModalFormId {
  ADD_EVIDENCE = 'add-evidence-modal-form',
  ADD_EVIDENCE_GENERATOR_CONNECTED_TAB = 'add-evidence-generator-modal-form-connected',
  ADD_EVIDENCE_GENERATOR_EVIDENCE_TAB = 'add-evidence-generator-modal-form-evidences',
  VIEW_EVIDENCE = 'view-evidence-modal-form',
  ADD_TEST = 'add-test-modal-form',
  ADD_DOCUMENT = 'add-document-modal-form',
  SELECT_RECEPTOR = 'select-receptor-modal-form',
  ACTIVATE_RECEPTOR = 'activate-receptor-modal-form',
  CONFIGURE_RECEPTOR = 'configure-receptor-modal-form',
  EDIT_RECEPTOR = 'edit-receptor-modal-form',
  ADD_POLICY = 'add-policy-modal-form',
  ADD_CONTROL = 'add-control-modal-form',
  ADD_CONTROL_OPTIONS = 'add-control-options',
  IMPORT_CONTROLS = 'import-controls-modal-form',
  ADD_AUDIT = 'add-audit-modal-form',
  EDIT_AUDIT = 'edit-audit-modal-form',
  BRING_CONTROL_INTO_AUDIT = 'bring-control-into-audit',
  ADD_CUSTOM_CONTROL = 'add-custom-control',
  ADD_CLOSED_AUDIT_FINAL_REPORT = 'upload-final-report',
  BRING_POLICY_INTO_AUDIT = 'bring-policy-into-audit',
  ADD_CUSTOM_POLICY = 'add-custom-policy',
  SHOW_POLICY_AUDITS_MODAL = 'show-policy-audits-modal',
  SHOW_POLICY_OBJECTIVES_MODAL = 'show-policy-objectives-modal',
  DELETE_AUDIT = 'delete-audit-modal',
  CHOOSE_COMPLIANCE_FRAMEWORK = 'choose-compliance-framework',
  REUSABLE_CONTROLS = 'reusable-controls',
  SELECT_CONTROLS = 'select-controls',
  REUSABLE_POLICIES = 'reusable-policies',
  SELECT_POLICIES = 'select-policies',
  CONFIRM_FRAMEWORK = 'confirm-framework',
  CHOOSE_REQUEST_UPLOAD_TYPE = 'choose-request-upload-type',
  ADD_DOCUMENT_REQUEST = 'add-document-request',
  ADD_REQUEST_BULK_UPLOAD = 'add-request-bulk-upload',
  ADD_CONTROLS_TO_REQUEST = 'add-controls-to-request',
  ADD_SERVICE = 'add-service',
  ADD_CUSTOM_SERVICE = 'add-custom-service',
  LINK_TO_REQUEST = 'link-to-request',
  _TEST_ENUM = '',
  ADD_REPORT_TO_SCAN = 'add-report-to-scan',
  ADD_CUSTOM_FRAMEWORK = 'add-custom-framework',
  FORM_ASSIGNEE_DROPDOWN = 'add-model-form-assignee-dropdown',
  ADD_RISK = 'add-risk',
  ADD_MANUAL_RISK = 'add-manual-risk',
  ADD_RISK_CSV = 'add-risk-csv',
  LINK_CONTROL_TO_RISK = 'link-control-to-risk', // in risks show page
  LINK_RISK_TO_CONTROL = 'link-risk-to-control', // in controls show page, risk tab
  UNLINK_RISK = 'unlink-risk',
  ADD_CONTROLS_CSV = 'add-controls-csv',
  REPLACE_CONTROLS_CSV = 'replace-controls-csv',
  RUN_AUDITBOT = 'run-auditbot',
  EDIT_AUDITBOT_RESULT = 'edit-auditbot-result',
  WELCOME_SAAS_BUYER_UPLOAD = 'welcome-saas-buyer-upload',
  WELCOME_BACK_MODAL = 'welcome-back-modal',
  COMPANY_INFO_FILES = 'company-info-files',
  NA_INFO = 'na-info',
  AUDIT_SCAN_MODAL = 'audit-scan-modal',
  FRAMEWORKS_NOT_COVERED = 'frameworks-not-covered',
  GO_TO_ROADMAP = 'go-to-roadmap',
  ADD_SECURITY_QUESTIONNAIRE_CSV = 'add-security-questionnaire-csv',
  ADD_SECURITY_QUESTIONNAIRE_QUESTION = 'add-security-questionnaire-question',
  ADD_KNOWLEDGE_BASE_CSV = 'add-knowledge-base-csv',
  MANAGE_KNOWLEDGE_BASE = 'manage-knowledge-base',
  EDIT_ANSWER = 'edit-answer',
  SCOPING_WIZARD = 'scoping-wizard',
  ADD_SERVICES = 'add-services',
  ADD_SERVICE_ROLES = 'add-service-roles',
  CREATE_CUSTOM_SERVICE = 'create-custom-service',
  WORKPAPERS_DOWNLOAD = 'workpapers-download',
  VIEW_RECEPTORS = 'view-receptors',
  WELCOME_FREE_SQ_CUSTOMER = 'welcome-free-sq-customer',
  CREATE_PROVIDERS = 'create-providers',
  ADD_SCOPING_SERVICES = 'add-scoping-services',
  CREATE_SCOPING_SERVICES = 'create-scoping-services',
  MARK_CONTROL_NA = 'mark-control-na',
  ADD_VENDOR = 'add-vendor',
  ADD_VENDOR_CSV = 'add-vendor-csv',
  CHOOSE_VENDOR_UPLOAD_TYPE = 'choose-vendor-upload-type',
  ROLES_NOT_FULFILLED = 'roles-not-fulfilled',
  ADD_ATTESTATION = 'add-attestation',
  LINK_VENDOR_TO_RISK = 'link-vendor-to-risk',
  LINK_RISK_TO_VENDOR = 'link-risk-to-vendor',
  UPSELL_QC = 'upsell-questionnaire-copilot',
  EDIT_DATE_LIMIT = 'edit-date-limit',
  VALIDATE_IMAGE_TIMESTAMP = 'validate-image-timestamp',
  EDIT_REPORT = 'edit-report',
  VIEW_DOCUMENT = 'view-document',
  UPDATE_AUDIT_SCAN_FREQUENCY = 'update-audit-scan-frequency',
  CONNECT_LINKER = 'connect-linker',
  GDRIVE_POLICY_IMPORT_CONNECT = 'gdrive-policy-import-connect',
  ADD_GRC_DOCUMENT = 'add-grc-document',
  VIEW_GRC_DOCUMENT = 'view-grc-document',
  POLICY_ACKNOWLEDGEMENT_SELECT = 'policy-acknowledgement-select',
  POLICY_ACKNOWLEDGEMENT_EDIT = 'policy-acknowledgement-edit',
  POLICY_RESPONSIBILITY = 'policy-responsibility',
  EVIDENCE_AUDITS = 'evidence-audits',
  RELEVANT_DATE = 'relevant-date',
  GENERATE_SYS_DESC = 'generate-system-description',
  UPDATE_CONTROL_STATUSES = 'update-control-statuses',
  EVIDENCE_GROUP_RELEVANT_DATE = 'evidence-group-relevant-date',
  ADD_EVIDENCE_GROUPS = 'add-evidence-groups',
  LINK_EVIDENCE_TO_CONTROLS = 'link-evidence-to-controls',
  LINK_EVIDENCE_TO_REQUESTS = 'link-evidence-to-requests',
  CUSTOM_FILE_TYPE_FILTER = 'custom-file-type-filter',
  CONTEXT_MODAL = 'context-modal',
}

type ModalFormBaseProps = {
  size?: ModalProps['size']
  show: boolean
  hide: () => void
  formId: ModalFormId
  hideButtons?: boolean
  hideCancel?: boolean
  submitRef?: React.RefObject<HTMLButtonElement>
  submitText?: string
  customSubmitButton?: React.ReactElement<HTMLButtonElement>
  customCancelButton?: React.ReactElement<HTMLButtonElement>
  enforceFocus?: boolean
  onDeactivate?: () => void
  hideDeactivate?: boolean
  disableSubmitButton?: boolean
  customBackText?: string
  customHeader?: JSX.Element
  title?: string | JSX.Element
  description?: string | JSX.Element
  hideClose?: boolean
  showOverflow?: boolean
  hideBack?: boolean
  backdrop?: 'static' | boolean
  fullscreenCfg?: string | true | undefined
  disableUserClose?: boolean
  disableKeyboardClose?: boolean
  isAIPowered?: boolean
  openOnDrag?: boolean
}

type ConditionalFooterProps =
  | {
      onBack?: () => void
      footerContent?: never
      customFooter?: JSX.Element
    }
  | {
      onBack?: never
      footerContent?: JSX.Element
      customFooter?: JSX.Element
    }

export type ModalFormProps = ConditionalFooterProps & ModalFormBaseProps

export const ModalForm = ({
  size = 'xl',
  show,
  hide,
  formId,
  title,
  description,
  children,
  hideButtons,
  hideCancel,
  submitRef,
  submitText,
  customSubmitButton,
  customCancelButton,
  enforceFocus = true,
  onBack,
  hideBack,
  customBackText,
  onDeactivate,
  hideDeactivate,
  footerContent,
  customFooter, // Custom Footer will override any footerContent & requires hideButtons={true}
  disableSubmitButton,
  customHeader,
  hideClose,
  showOverflow = false,
  backdrop = true,
  fullscreenCfg,
  disableUserClose = false,
  disableKeyboardClose = false,
  isAIPowered,
  openOnDrag,
}: PropsWithChildren<ModalFormProps>): JSX.Element => {
  const { setTargetModal } = useDragAndDrop()

  useEffect(() => {
    if (!show && openOnDrag) {
      setTargetModal(formId)
    }
    if (show && openOnDrag) {
      setTargetModal(null)
    }

    return () => {
      setTargetModal(null)
    }
  }, [formId, openOnDrag, setTargetModal, show])

  return (
    <ModalContainer
      show={show}
      backdrop={
        backdrop
          ? backdrop
          : disableUserClose || disableKeyboardClose
          ? 'static'
          : disableUserClose || disableKeyboardClose
      }
      centered
      keyboard={!disableUserClose && !disableKeyboardClose}
      size={size}
      enforceFocus={enforceFocus}
      onHide={hide}
      fullscreen={fullscreenCfg}
    >
      {!(hideClose || disableUserClose) && (
        <CloseButton
          position="absolute"
          top="s"
          right="s"
          onClick={(e: React.MouseEvent) => {
            e.preventDefault()
            hide()
          }}
        />
      )}
      <ModalHeader>
        {customHeader ? (
          customHeader
        ) : (
          <>
            {isAIPowered ? (
              <AIPoweredModalHeader title={title as string} />
            ) : (
              <ModalTitle>{title}</ModalTitle>
            )}
            {description && <ModalDescription>{description}</ModalDescription>}
          </>
        )}
      </ModalHeader>
      <ModalBody $showOverflow={showOverflow}>{children}</ModalBody>
      {/* If a customFooter is used, we should only render that and no other footer content */}
      {customFooter && <ModalFooter>{customFooter}</ModalFooter>}
      {!hideButtons && !customFooter && (
        <ModalFooter>
          <ModalButtonGroup>
            {!hideDeactivate && onDeactivate && (
              <MediumCancelButton onClick={onDeactivate}>
                <ActivateSVG height="16px" />
                Deactivate
              </MediumCancelButton>
            )}
          </ModalButtonGroup>
          <ModalButtonGroup>
            {footerContent ||
              (isFunction(onBack) && !hideBack && (
                <MediumCancelButton onClick={onBack}>
                  {customBackText || (
                    <>
                      <BackIcon />
                      Back
                    </>
                  )}
                </MediumCancelButton>
              ))}
          </ModalButtonGroup>
          <ModalButtonGroup>
            {!(hideCancel || disableUserClose) && (
              <>
                {customCancelButton || (
                  <MediumCancelButton onClick={hide}>Cancel</MediumCancelButton>
                )}
              </>
            )}
            {customSubmitButton || (
              <StandardButton
                ref={submitRef}
                type="submit"
                form={formId}
                variant={
                  isAIPowered
                    ? StandardButtonVariant.AI_PRIMARY
                    : StandardButtonVariant.PRIMARY
                }
                buttonSize={StandardButtonSize.MEDIUM}
                disabled={disableSubmitButton}
              >
                {submitText || 'Submit'}
              </StandardButton>
            )}
          </ModalButtonGroup>
        </ModalFooter>
      )}
    </ModalContainer>
  )
}

/**
 * @deprecated
 * use `StandardOpenModalButton` instead
 */
export const NavigateToModalSubmitButton = ({
  modal,
  text = 'Continue',
  variant = StandardButtonVariant.PRIMARY,
  size = StandardButtonSize.MEDIUM,
}: {
  modal: ModalFormId
  text?: string
  variant?: StandardButtonVariant
  size?: StandardButtonSize
}): JSX.Element => {
  const navigate = useNavigate()

  return (
    <>
      <ModalFormToggle
        modalFormId={modal}
        as={({ to }) => {
          return (
            <StandardButton
              variant={variant}
              buttonSize={size}
              onClick={(e: React.MouseEvent) => {
                e.preventDefault()
                return navigate(to)
              }}
            >
              <p>{text}</p>
            </StandardButton>
          )
        }}
      />
    </>
  )
}
