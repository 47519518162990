import {
  ChatContextSource,
  ChatContextSourceIncludedIds,
  ChatContextSourceType,
  CreateChatContextRequest,
} from '@trustero/trustero-api-web/lib/chat/chat_pb'

export const ChatContextTypes = ['kb', 'policy', 'control'] as const
export type ChatContextType = (typeof ChatContextTypes)[number]

export type ChatContext = {
  [K in ChatContextType]: {
    includeAll: boolean
    included: { [key: string]: boolean }
  }
}

export const getChatContextSourceType = (
  type: ChatContextType,
): ChatContextSourceType => {
  switch (type) {
    case 'kb':
      return ChatContextSourceType.KNOWLEDGE_BASE
    case 'policy':
      return ChatContextSourceType.POLICY
    default:
      return ChatContextSourceType.CONTROL
  }
}

export const getCreateChatContextRequest = (
  chatContext: ChatContext,
): CreateChatContextRequest => {
  const chatContextRequest = new CreateChatContextRequest()
  chatContextRequest.setSourcesList(
    ChatContextTypes.map((type) => {
      const source = new ChatContextSource()
      if (chatContext[type].includeAll) {
        source.setIncludeAll(true)
      } else {
        const includedIds = Object.keys(chatContext[type].included).filter(
          (id) => chatContext[type].included[id],
        )
        source.setIncludedIds(
          new ChatContextSourceIncludedIds().setIdsList(includedIds),
        )
      }
      source.setType(getChatContextSourceType(type))

      return source
    }),
  )
  return chatContextRequest
}
