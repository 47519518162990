import React from 'react'
import log from 'loglevel'
import { Flags, FlagsProvider } from 'react-feature-flags'
import { AccountPromiseClient } from '@trustero/trustero-api-web/lib/account/account_grpc_web_pb'
import {
  FeatureAccessRequest,
  FeatureAccessResponse,
  FEATURE_ACCESS,
} from '@trustero/trustero-api-web/lib/account/account_pb'
import { useSwrImmutableGrpc } from 'src/components/async/useSwrImmutableGrpc'
import { GrpcResponse, NewGrpcResponse } from 'src/components/async/hooks/types'
import { loadFromStorage } from '../Utils/storage'
import { EnvConfig } from '../config/config'

const logger = log.getLogger('context')

export enum GatekeeperFlag {
  REGISTER_PAGES = 'registerPages',
  RELEVANT_DATE = 'relevantDate',
  MULTI_FRAMEWORK = 'multiFramework',
  CUSTOM_RECEPTOR = 'customReceptor',
  GRID_COLUMN_SORT = 'gridColumnSort',
  SUGGESTED_AUTOMATED_EVIDENCE = 'suggestedAutomatedEvidence',
  MULTI_FRAMEWORK_SUGGESTIONS = 'multiFrameworkSuggestions',
  STUB_FRAMEWORKS = 'stubFrameworks',
  REPORT_SCAN = 'reportScan',
  SUGGESTED_DLR_EVIDENCE = 'suggestedDLREvidence',
  CRITERIA_CONTROL_MAPPING = 'criteriaControlMapping',
  CONTROL_IDS = 'controlIds',
  RISK_REGISTER = 'riskRegister',
  STANDALONE_SCANNER = 'standaloneScanner',
  NAV_UPDATES = 'navUpdates',
  SNYK_RECEPTOR = 'snykReceptor',
  AUDIT_BOT = 'auditBot',
  SERVICES_OVERHAUL = 'servicesOverhaul',
  MIRADORE_RECEPTOR = 'miradoreReceptor',
  SOPHOS_RECEPTOR = 'sophosReceptor',
  VENDOR_MANAGEMENT = 'vendorManagement',
  JUMPCLOUD_RECEPTOR = 'jumpcloudReceptor',
  MS_LOGIN = 'msLogin',
  KNOWBE4_RECEPTOR = 'knowbe4Receptor',
  MULTI_FRAMEWORK_AUDITBOT = 'multiFrameworkAuditbot',
  SERVICE_ACCOUNTS = 'serviceAccounts',
  ASK_AI_COPILOT = 'askAiCopilot',
  CC_ROADMAP = 'ccRoadmap',
  EXCLUDE_SERVICES = 'excludeServices',
  CONTINUOUS_MONITORING = 'continuousMonitoring',
  GOOGLE_DRIVE_AUTH = 'googleDriveAuth',
  JIRA_RECEPTOR = 'jiraReceptor',
  GOOGLE_DRIVE_POLICY_IMPORT = 'googleDrivePolicyImport',
  POLICY_RESPONSIBILITY = 'policyResponsibility',
  NEW_EVIDENCE_TABLE = 'newEvidenceTable',
  GENERATE_SYSTEM_DESCRIPTION = 'generateSystemDescription',
  ANECDOTES_RECEPTOR = 'anecdotesReceptor',
  RELEVANT_DATE_FORM = 'relevantDateForm',
  EVIDENCE_INDEX = 'evidenceIndex',
  SLACK_RECEPTOR = 'slackReceptor',
  ROADMAP_UPDATES = 'roadmapUpdates',
  SONARQUBE_CLOUD_RECEPTOR = 'sonarqubeCloudReceptor',
  FRESHDESK_RECEPTOR = 'freshdeskReceptor',
  JSM_RECEPTOR = 'jsmReceptor',
  ZENDESK_RECEPTOR = 'zendeskReceptor',
  TRUSTERO_INTELLIGENCE_V1 = 'trusteroIntelligenceV1',
}

function isLocallyEnabled(flag: string): boolean | null {
  // for our internal use, we want to allow users to override the value
  // of feature flags by setting keys in localStorage
  // Keys will be named "<env>_feature_<flag>"
  // The default value of `null` allows us to tell the caller
  // that the value was not found in localStorage
  const value = loadFromStorage(`feature_${flag}`, null)
  return value
}

export const GatekeeperProvider = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  // verify that all defined flags are in our enum
  const flagData =
    (EnvConfig.featureFlags as { [key in GatekeeperFlag]: boolean }) || {}
  const allowedFlags = Object.values(GatekeeperFlag)
  for (const flag of Object.keys(flagData) as GatekeeperFlag[]) {
    if (!allowedFlags.includes(flag)) {
      logger.error(`Unknown gatekeeper flag in config file: ${flag}`)
    }
  }
  // delegate to FlagsProvider
  const featureFlags = Object.entries(EnvConfig.featureFlags || {}).map(
    ([flag, isEnvEnabled]) => {
      return {
        name: flag,
        isActive: isLocallyEnabled(flag) ?? isEnvEnabled,
      }
    },
  )
  return <FlagsProvider value={featureFlags}>{children}</FlagsProvider>
}

/*
 * This function is used to determine if a feature flag is enabled.
 * @param flag - The gatekeeper flag(s) to check - can be a single flag or an array of flags
 * @returns true if all of the flags are enabled, false otherwise
 */

export const IsGatekeeperEnabled = (
  flag: GatekeeperFlag | GatekeeperFlag[],
): boolean => {
  // We only want to return true if all flags are enabled
  if (Array.isArray(flag)) {
    return !flag.some((f) => !IsGatekeeperEnabled(f))
  }

  const isEnvEnabled = !!(
    EnvConfig?.featureFlags && EnvConfig.featureFlags[flag]
  )
  return isLocallyEnabled(flag) ?? isEnvEnabled
}

type GatekeeperProps = {
  authorizedFlags: GatekeeperFlag[]
  children?: JSX.Element | JSX.Element[]
  renderOff?: () => JSX.Element | null
  renderOn?: () => JSX.Element | null
}

export const Gatekeeper = ({
  authorizedFlags,
  renderOff = () => null,
  renderOn = () => null,
  children,
}: GatekeeperProps): JSX.Element => {
  if (authorizedFlags.some((f) => !Object.values(GatekeeperFlag).includes(f))) {
    logger.error('Invalid Gatekeeper flag was passed in')
    return <></>
  }

  return (
    <Flags
      renderOff={renderOff}
      renderOn={renderOn}
      authorizedFlags={authorizedFlags}
    >
      {children}
    </Flags>
  )
}

type ReverseGatekeeperComponentProps = {
  shouldHide: <t>(arg: t) => boolean
  shouldHideArg: unknown
  authorizedFlags: GatekeeperFlag | GatekeeperFlag[]
  children?: JSX.Element | JSX.Element[]
}

/**
 * This component is used to hide a component if the gatekeeper flag is NOT enabled and the shouldHide function returns true
 * @param shouldHide - a function that takes in a generic argument and returns a boolean
 * @param shouldHideArg - the argument to pass to the shouldHide function
 * @param authorizedFlags - the gatekeeper flag(s) to check
 * @param children - the component to render if the gatekeeper flag is enabled or the shouldHide function returns false
 * @returns the null if the gatekeeper flag is NOT enabled and the shouldHide function returns true, otherwise it returns the children
 */
export const ReverseGatekeeperComponent = ({
  shouldHide,
  shouldHideArg,
  authorizedFlags,
  children,
}: ReverseGatekeeperComponentProps): JSX.Element => {
  const gatekeeper = IsGatekeeperEnabled(authorizedFlags)
  return !gatekeeper && shouldHide(shouldHideArg) ? <></> : <>{children}</>
}

/**
 * Determine feature access from the backend
 */
export const useHasFeatureAccess = (
  featureType: FEATURE_ACCESS,
  shouldFetch = true,
): GrpcResponse<FeatureAccessResponse> => {
  const { response } = useSwrImmutableGrpc(
    AccountPromiseClient.prototype.checkFeatureAccess,
    new FeatureAccessRequest().setFeatureType(featureType),
    shouldFetch && featureType > -1, // Ensure we don't call the API if the feature type is not valid (begins with 0 enum)
  )

  return NewGrpcResponse(response)
}

export const useHasGoogleDriveAuth = (): boolean => {
  const hasEnvAccess = IsGatekeeperEnabled(GatekeeperFlag.GOOGLE_DRIVE_AUTH)

  return hasEnvAccess
}

export const hasControlStatus = (): boolean =>
  IsGatekeeperEnabled(GatekeeperFlag.CONTINUOUS_MONITORING)

export const useHasGenerateSystemDescription = (): boolean =>
  IsGatekeeperEnabled(GatekeeperFlag.GENERATE_SYSTEM_DESCRIPTION)

export const useHasAnecdotesReceptor =
  (): GrpcResponse<FeatureAccessResponse> => {
    return useHasFeatureAccess(FEATURE_ACCESS.ANECDOTES_EVIDENCE_INDEX)
  }

export const hasRoadmapUpdates = (): boolean =>
  IsGatekeeperEnabled(GatekeeperFlag.ROADMAP_UPDATES)
