// source: chat/chat.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.chat.AnswerReactionType', null, global);
goog.exportSymbol('proto.chat.ChatContext', null, global);
goog.exportSymbol('proto.chat.ChatContextSource', null, global);
goog.exportSymbol('proto.chat.ChatContextSource.SourceCase', null, global);
goog.exportSymbol('proto.chat.ChatContextSourceIncludedIds', null, global);
goog.exportSymbol('proto.chat.ChatContextSourceType', null, global);
goog.exportSymbol('proto.chat.CreateChatContextRequest', null, global);
goog.exportSymbol('proto.chat.CreateChatContextResponse', null, global);
goog.exportSymbol('proto.chat.GetChatContextRequest', null, global);
goog.exportSymbol('proto.chat.GetChatContextResponse', null, global);
goog.exportSymbol('proto.chat.UpdateChatContextRequest', null, global);
goog.exportSymbol('proto.chat.UpdateChatContextResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat.ChatContextSourceIncludedIds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.chat.ChatContextSourceIncludedIds.repeatedFields_, null);
};
goog.inherits(proto.chat.ChatContextSourceIncludedIds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat.ChatContextSourceIncludedIds.displayName = 'proto.chat.ChatContextSourceIncludedIds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat.ChatContextSource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.chat.ChatContextSource.oneofGroups_);
};
goog.inherits(proto.chat.ChatContextSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat.ChatContextSource.displayName = 'proto.chat.ChatContextSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat.ChatContext = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.chat.ChatContext.repeatedFields_, null);
};
goog.inherits(proto.chat.ChatContext, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat.ChatContext.displayName = 'proto.chat.ChatContext';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat.GetChatContextRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chat.GetChatContextRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat.GetChatContextRequest.displayName = 'proto.chat.GetChatContextRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat.GetChatContextResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chat.GetChatContextResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat.GetChatContextResponse.displayName = 'proto.chat.GetChatContextResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat.CreateChatContextRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.chat.CreateChatContextRequest.repeatedFields_, null);
};
goog.inherits(proto.chat.CreateChatContextRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat.CreateChatContextRequest.displayName = 'proto.chat.CreateChatContextRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat.CreateChatContextResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chat.CreateChatContextResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat.CreateChatContextResponse.displayName = 'proto.chat.CreateChatContextResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat.UpdateChatContextRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chat.UpdateChatContextRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat.UpdateChatContextRequest.displayName = 'proto.chat.UpdateChatContextRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chat.UpdateChatContextResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chat.UpdateChatContextResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chat.UpdateChatContextResponse.displayName = 'proto.chat.UpdateChatContextResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.chat.ChatContextSourceIncludedIds.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat.ChatContextSourceIncludedIds.prototype.toObject = function(opt_includeInstance) {
  return proto.chat.ChatContextSourceIncludedIds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat.ChatContextSourceIncludedIds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat.ChatContextSourceIncludedIds.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat.ChatContextSourceIncludedIds}
 */
proto.chat.ChatContextSourceIncludedIds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat.ChatContextSourceIncludedIds;
  return proto.chat.ChatContextSourceIncludedIds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat.ChatContextSourceIncludedIds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat.ChatContextSourceIncludedIds}
 */
proto.chat.ChatContextSourceIncludedIds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat.ChatContextSourceIncludedIds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat.ChatContextSourceIncludedIds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat.ChatContextSourceIncludedIds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat.ChatContextSourceIncludedIds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.chat.ChatContextSourceIncludedIds.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.chat.ChatContextSourceIncludedIds} returns this
 */
proto.chat.ChatContextSourceIncludedIds.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.chat.ChatContextSourceIncludedIds} returns this
 */
proto.chat.ChatContextSourceIncludedIds.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chat.ChatContextSourceIncludedIds} returns this
 */
proto.chat.ChatContextSourceIncludedIds.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.chat.ChatContextSource.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.chat.ChatContextSource.SourceCase = {
  SOURCE_NOT_SET: 0,
  INCLUDE_ALL: 2,
  INCLUDED_IDS: 3
};

/**
 * @return {proto.chat.ChatContextSource.SourceCase}
 */
proto.chat.ChatContextSource.prototype.getSourceCase = function() {
  return /** @type {proto.chat.ChatContextSource.SourceCase} */(jspb.Message.computeOneofCase(this, proto.chat.ChatContextSource.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat.ChatContextSource.prototype.toObject = function(opt_includeInstance) {
  return proto.chat.ChatContextSource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat.ChatContextSource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat.ChatContextSource.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    includeAll: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    includedIds: (f = msg.getIncludedIds()) && proto.chat.ChatContextSourceIncludedIds.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat.ChatContextSource}
 */
proto.chat.ChatContextSource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat.ChatContextSource;
  return proto.chat.ChatContextSource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat.ChatContextSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat.ChatContextSource}
 */
proto.chat.ChatContextSource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.chat.ChatContextSourceType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeAll(value);
      break;
    case 3:
      var value = new proto.chat.ChatContextSourceIncludedIds;
      reader.readMessage(value,proto.chat.ChatContextSourceIncludedIds.deserializeBinaryFromReader);
      msg.setIncludedIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat.ChatContextSource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat.ChatContextSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat.ChatContextSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat.ChatContextSource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIncludedIds();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.chat.ChatContextSourceIncludedIds.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChatContextSourceType type = 1;
 * @return {!proto.chat.ChatContextSourceType}
 */
proto.chat.ChatContextSource.prototype.getType = function() {
  return /** @type {!proto.chat.ChatContextSourceType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.chat.ChatContextSourceType} value
 * @return {!proto.chat.ChatContextSource} returns this
 */
proto.chat.ChatContextSource.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool include_all = 2;
 * @return {boolean}
 */
proto.chat.ChatContextSource.prototype.getIncludeAll = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.chat.ChatContextSource} returns this
 */
proto.chat.ChatContextSource.prototype.setIncludeAll = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.chat.ChatContextSource.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.chat.ChatContextSource} returns this
 */
proto.chat.ChatContextSource.prototype.clearIncludeAll = function() {
  return jspb.Message.setOneofField(this, 2, proto.chat.ChatContextSource.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chat.ChatContextSource.prototype.hasIncludeAll = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ChatContextSourceIncludedIds included_ids = 3;
 * @return {?proto.chat.ChatContextSourceIncludedIds}
 */
proto.chat.ChatContextSource.prototype.getIncludedIds = function() {
  return /** @type{?proto.chat.ChatContextSourceIncludedIds} */ (
    jspb.Message.getWrapperField(this, proto.chat.ChatContextSourceIncludedIds, 3));
};


/**
 * @param {?proto.chat.ChatContextSourceIncludedIds|undefined} value
 * @return {!proto.chat.ChatContextSource} returns this
*/
proto.chat.ChatContextSource.prototype.setIncludedIds = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.chat.ChatContextSource.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chat.ChatContextSource} returns this
 */
proto.chat.ChatContextSource.prototype.clearIncludedIds = function() {
  return this.setIncludedIds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chat.ChatContextSource.prototype.hasIncludedIds = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.chat.ChatContext.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat.ChatContext.prototype.toObject = function(opt_includeInstance) {
  return proto.chat.ChatContext.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat.ChatContext} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat.ChatContext.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sourcesList: jspb.Message.toObjectList(msg.getSourcesList(),
    proto.chat.ChatContextSource.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat.ChatContext}
 */
proto.chat.ChatContext.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat.ChatContext;
  return proto.chat.ChatContext.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat.ChatContext} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat.ChatContext}
 */
proto.chat.ChatContext.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.chat.ChatContextSource;
      reader.readMessage(value,proto.chat.ChatContextSource.deserializeBinaryFromReader);
      msg.addSources(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat.ChatContext.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat.ChatContext.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat.ChatContext} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat.ChatContext.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.chat.ChatContextSource.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.chat.ChatContext.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.chat.ChatContext} returns this
 */
proto.chat.ChatContext.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ChatContextSource sources = 2;
 * @return {!Array<!proto.chat.ChatContextSource>}
 */
proto.chat.ChatContext.prototype.getSourcesList = function() {
  return /** @type{!Array<!proto.chat.ChatContextSource>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.chat.ChatContextSource, 2));
};


/**
 * @param {!Array<!proto.chat.ChatContextSource>} value
 * @return {!proto.chat.ChatContext} returns this
*/
proto.chat.ChatContext.prototype.setSourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.chat.ChatContextSource=} opt_value
 * @param {number=} opt_index
 * @return {!proto.chat.ChatContextSource}
 */
proto.chat.ChatContext.prototype.addSources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.chat.ChatContextSource, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chat.ChatContext} returns this
 */
proto.chat.ChatContext.prototype.clearSourcesList = function() {
  return this.setSourcesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat.GetChatContextRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chat.GetChatContextRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat.GetChatContextRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat.GetChatContextRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat.GetChatContextRequest}
 */
proto.chat.GetChatContextRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat.GetChatContextRequest;
  return proto.chat.GetChatContextRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat.GetChatContextRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat.GetChatContextRequest}
 */
proto.chat.GetChatContextRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat.GetChatContextRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat.GetChatContextRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat.GetChatContextRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat.GetChatContextRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.chat.GetChatContextRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.chat.GetChatContextRequest} returns this
 */
proto.chat.GetChatContextRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat.GetChatContextResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chat.GetChatContextResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat.GetChatContextResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat.GetChatContextResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    context: (f = msg.getContext()) && proto.chat.ChatContext.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat.GetChatContextResponse}
 */
proto.chat.GetChatContextResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat.GetChatContextResponse;
  return proto.chat.GetChatContextResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat.GetChatContextResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat.GetChatContextResponse}
 */
proto.chat.GetChatContextResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.chat.ChatContext;
      reader.readMessage(value,proto.chat.ChatContext.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat.GetChatContextResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat.GetChatContextResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat.GetChatContextResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat.GetChatContextResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.chat.ChatContext.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChatContext context = 1;
 * @return {?proto.chat.ChatContext}
 */
proto.chat.GetChatContextResponse.prototype.getContext = function() {
  return /** @type{?proto.chat.ChatContext} */ (
    jspb.Message.getWrapperField(this, proto.chat.ChatContext, 1));
};


/**
 * @param {?proto.chat.ChatContext|undefined} value
 * @return {!proto.chat.GetChatContextResponse} returns this
*/
proto.chat.GetChatContextResponse.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chat.GetChatContextResponse} returns this
 */
proto.chat.GetChatContextResponse.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chat.GetChatContextResponse.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.chat.CreateChatContextRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat.CreateChatContextRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chat.CreateChatContextRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat.CreateChatContextRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat.CreateChatContextRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourcesList: jspb.Message.toObjectList(msg.getSourcesList(),
    proto.chat.ChatContextSource.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat.CreateChatContextRequest}
 */
proto.chat.CreateChatContextRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat.CreateChatContextRequest;
  return proto.chat.CreateChatContextRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat.CreateChatContextRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat.CreateChatContextRequest}
 */
proto.chat.CreateChatContextRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.chat.ChatContextSource;
      reader.readMessage(value,proto.chat.ChatContextSource.deserializeBinaryFromReader);
      msg.addSources(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat.CreateChatContextRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat.CreateChatContextRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat.CreateChatContextRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat.CreateChatContextRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.chat.ChatContextSource.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ChatContextSource sources = 2;
 * @return {!Array<!proto.chat.ChatContextSource>}
 */
proto.chat.CreateChatContextRequest.prototype.getSourcesList = function() {
  return /** @type{!Array<!proto.chat.ChatContextSource>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.chat.ChatContextSource, 2));
};


/**
 * @param {!Array<!proto.chat.ChatContextSource>} value
 * @return {!proto.chat.CreateChatContextRequest} returns this
*/
proto.chat.CreateChatContextRequest.prototype.setSourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.chat.ChatContextSource=} opt_value
 * @param {number=} opt_index
 * @return {!proto.chat.ChatContextSource}
 */
proto.chat.CreateChatContextRequest.prototype.addSources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.chat.ChatContextSource, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chat.CreateChatContextRequest} returns this
 */
proto.chat.CreateChatContextRequest.prototype.clearSourcesList = function() {
  return this.setSourcesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat.CreateChatContextResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chat.CreateChatContextResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat.CreateChatContextResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat.CreateChatContextResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    context: (f = msg.getContext()) && proto.chat.ChatContext.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat.CreateChatContextResponse}
 */
proto.chat.CreateChatContextResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat.CreateChatContextResponse;
  return proto.chat.CreateChatContextResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat.CreateChatContextResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat.CreateChatContextResponse}
 */
proto.chat.CreateChatContextResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.chat.ChatContext;
      reader.readMessage(value,proto.chat.ChatContext.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat.CreateChatContextResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat.CreateChatContextResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat.CreateChatContextResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat.CreateChatContextResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.chat.ChatContext.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChatContext context = 1;
 * @return {?proto.chat.ChatContext}
 */
proto.chat.CreateChatContextResponse.prototype.getContext = function() {
  return /** @type{?proto.chat.ChatContext} */ (
    jspb.Message.getWrapperField(this, proto.chat.ChatContext, 1));
};


/**
 * @param {?proto.chat.ChatContext|undefined} value
 * @return {!proto.chat.CreateChatContextResponse} returns this
*/
proto.chat.CreateChatContextResponse.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chat.CreateChatContextResponse} returns this
 */
proto.chat.CreateChatContextResponse.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chat.CreateChatContextResponse.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat.UpdateChatContextRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chat.UpdateChatContextRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat.UpdateChatContextRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat.UpdateChatContextRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    context: (f = msg.getContext()) && proto.chat.ChatContext.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat.UpdateChatContextRequest}
 */
proto.chat.UpdateChatContextRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat.UpdateChatContextRequest;
  return proto.chat.UpdateChatContextRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat.UpdateChatContextRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat.UpdateChatContextRequest}
 */
proto.chat.UpdateChatContextRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.chat.ChatContext;
      reader.readMessage(value,proto.chat.ChatContext.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat.UpdateChatContextRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat.UpdateChatContextRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat.UpdateChatContextRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat.UpdateChatContextRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.chat.ChatContext.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChatContext context = 1;
 * @return {?proto.chat.ChatContext}
 */
proto.chat.UpdateChatContextRequest.prototype.getContext = function() {
  return /** @type{?proto.chat.ChatContext} */ (
    jspb.Message.getWrapperField(this, proto.chat.ChatContext, 1));
};


/**
 * @param {?proto.chat.ChatContext|undefined} value
 * @return {!proto.chat.UpdateChatContextRequest} returns this
*/
proto.chat.UpdateChatContextRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chat.UpdateChatContextRequest} returns this
 */
proto.chat.UpdateChatContextRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chat.UpdateChatContextRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chat.UpdateChatContextResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chat.UpdateChatContextResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chat.UpdateChatContextResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat.UpdateChatContextResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    context: (f = msg.getContext()) && proto.chat.ChatContext.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chat.UpdateChatContextResponse}
 */
proto.chat.UpdateChatContextResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chat.UpdateChatContextResponse;
  return proto.chat.UpdateChatContextResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chat.UpdateChatContextResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chat.UpdateChatContextResponse}
 */
proto.chat.UpdateChatContextResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.chat.ChatContext;
      reader.readMessage(value,proto.chat.ChatContext.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chat.UpdateChatContextResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chat.UpdateChatContextResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chat.UpdateChatContextResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chat.UpdateChatContextResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.chat.ChatContext.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChatContext context = 1;
 * @return {?proto.chat.ChatContext}
 */
proto.chat.UpdateChatContextResponse.prototype.getContext = function() {
  return /** @type{?proto.chat.ChatContext} */ (
    jspb.Message.getWrapperField(this, proto.chat.ChatContext, 1));
};


/**
 * @param {?proto.chat.ChatContext|undefined} value
 * @return {!proto.chat.UpdateChatContextResponse} returns this
*/
proto.chat.UpdateChatContextResponse.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chat.UpdateChatContextResponse} returns this
 */
proto.chat.UpdateChatContextResponse.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chat.UpdateChatContextResponse.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * @enum {number}
 */
proto.chat.ChatContextSourceType = {
  KNOWLEDGE_BASE: 0,
  POLICY: 1,
  CONTROL: 2,
  DOCUMENT_GROUP: 3,
  GRC_FRAMEWORK: 4,
  RECEPTOR: 5,
  SUPPORT_DOCUMENT: 6,
  COMPANY: 7
};

/**
 * @enum {number}
 */
proto.chat.AnswerReactionType = {
  LIKE: 0,
  DISLIKE: 1
};

goog.object.extend(exports, proto.chat);
